import React, {useContext, useState, createContext, useEffect} from 'react'
import { useApolloClient } from '@apollo/client'
import { useClientUser } from 'contexts/ClientUserProvider'

import { UPDATE_COMPANY, COMPANIES, CLIENT_GERISTRATION } from 'query'

const GeneralContext = createContext(null);

const GeneralProvider = ({children}) => {
  const apolloClient = useApolloClient();

  const clientRegistration = async (input) => {
    try {
      let result = await apolloClient.mutate({
        mutation: CLIENT_GERISTRATION,
        variables: { input },
      })
      return result?.data?.clientRegistration || null;
    } catch(err) {
      console.error("GeneralProvider clientRegistration", err)
      throw err
    }
  }

  return (
    <GeneralContext.Provider
      value={{
        clientRegistration
      }}>
      {children}
    </GeneralContext.Provider>
  );
};

const useGeneral = () => {
  const General = useContext(GeneralContext);
  if (General == null) {
    throw new Error('useGeneral() called outside of a GeneralProvider?');
  }
  return General;
};

export { GeneralProvider, useGeneral };