import { gql } from '@apollo/client';
import {FEEDBACK_ATTRIBUTES} from './general'

const STORES_ATTRIBUTES = gql`
  fragment storeAttributes on Store {
    _id
    display_name
    store_name
    #store_comapny_reg_no
    #company_id
    companies{
      _id
      display_name
    }
    email
    tel
    website_url
    description
    logo_image
    #images
    status
    currency_id
    #name
    order_status
    store_company_reg_no
    #last_bill_number
    #isOpen
    #operationType
    whatsapp   
    xp_printer_sn
    whatsapp
    bank_id
    bank_acc_no
    permissions
  }
`

const OPERATION_HOUR_ATTRIBUTES = gql`
  fragment OperationHourAttributes on OperationHour {
    _id
    time_zone
    utc_close_day
    utc_close_time
    utc_open_day
    utc_open_time
    store_id
    status
  }
`

export const WATCH_STORE = gql`
  subscription ($_id: ID){
    watchStore(_id: $_id){
      _id
      isOpen
    }
  }
`;

export const OPERATION_HOUR = gql`
  query($store_id: ID){
    operationHour(store_id:$store_id){
      ...OperationHourAttributes
    }
  }${OPERATION_HOUR_ATTRIBUTES}
`;

export const STORE = gql`
  query($store_id: ID){
    store(store_id:$store_id){
      ...storeAttributes
    }
  }${STORES_ATTRIBUTES}
`;

export const ADD_STORE = gql`
mutation addStore($input:StoreInput){
  addStore(input:$input){
    ...StandardFeedback
  }
}${FEEDBACK_ATTRIBUTES}
`;

export const UPDATE_STORE_IS_OPEN = gql`
  mutation ($_id: ID, $isOpen:Boolean){
    updateStoreIsOpen(_id:$_id, isOpen:$isOpen){
      ...storeAttributes
    }
  }${STORES_ATTRIBUTES}
`;

export const UPDATE_STORE = gql`
  mutation updateStore($input:StoreInput){
    updateStore(input:$input){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const DELETE_STORE = gql `
  mutation deleteStore($_id: ID){
    deleteStore(_id:$_id){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`